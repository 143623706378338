.home-video {
    position: relative;
    &__title {
        @include heading1(56px);
        margin: 0 0 24px 0;
        a {
            color: $dark;
            text-decoration: none;
            &:hover {
                color: $highlight;
            }
        }
    }
    &__poster {
        overflow: hidden;
        padding-top: 56.25%;
        position: relative;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
        }
    }

/*
    &__poster {
        position: relative;
        .modal-open {
            height: 102px;
            left: 50%;
            outline: none;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 102px;
            .home-video__poster--play {
                #Oval {
                    fill: transparent;
                     stroke: #FD6600;
                }
            }
            &:hover {
                #Oval {
                    fill: $highlight;
                }
            }
        }
        &--image {
            height: 400px;
            object-fit: cover;
            width: 746px;
        }
    }
*/
    &__stats {
        &--date {
            @include frankHv(16px);
            margin-right: 5px;
            padding-right: 15px;
            position: relative;
            &:after {
                bottom: 0;
                content: url('../img/dot.svg');
                position: absolute;
                right: 0;
            }
        }
        &--category {
            color: #9b9b9b;
            font-size: 16px;
            margin-right: 5px;
            padding-right: 15px;
            position: relative;
            text-decoration: none;
            text-transform: uppercase;
            &:after {
                bottom: 0;
                content: url('../img/dot.svg');
                position: absolute;
                right: 0;
            }
            &:hover {
                color: $highlight;
            }
        }
        &--keyword {
            color: #9b9b9b;
            font-size: 16px;
            text-decoration: none;
            text-transform: uppercase;
            &:hover {
                color: $highlight;
            }
        }
    }
    &__teaser-text {

    }
}

.home-event {
    h3 {
        @include frankBkCom(36px);
        margin: 0;
    }
    &__title {
        @include frankBkCon(34px);
        line-height: 1.0;
        margin: 12px 0 0 0;

    }
    &__stats {
        margin: 0;
        &--date {
            @include frankHv(16px);
            margin-right: 5px;
            padding-right: 15px;
            position: relative;
            &:after {
                bottom: 0;
                content: url('../img/dot.svg');
                position: absolute;
                right: 0;
            }
        }
        &--location {
            color: #9b9b9b;
            font-size: 16px;
            margin-right: 10px;
            padding-right: 15px;
            position: relative;
            text-decoration: none;
            text-transform: uppercase;
        }
    }
    &__teaser {

    }
    button {
        width: 100%;
    }
}

.content__main .divider:last-child {
        display: none;
}