// Position 'fixed' is a unicorn on mobile devices.
// Mobile requires 'absolute', so we need to figure out
// whether or not this is a mobile device WITHOUT
// device detection. So we narrow down the chances
// by detecting screen width / is touch enabled / and
// whether or not the primary pointer is fine or not.

// Pointer can be none, fine or coarse
// NOTE: Firefox as of 2019 STILL does not support
// (pointer: x) you STILL need to use a -moz prefix!
@media (min-width: 48em), (-moz-touch-enabled: 1) and (pointer: fine) { 
    #nav-toggle {
        display: none;
    }
    .header {
        background-color: #fff;
        position: fixed;
        transition: background-color .3s ease-out;
        width: 100%;
        &__logo {
            border-bottom: 2px $light solid;

        }
        &__nav {
            background: transparent;
            min-height: 0;
            overflow: visible;
            padding: 28px 0 32px 0;
            position: relative;
            transition: padding .3s ease-out;
            width: auto;
            &--primary {
                display: flex;
                justify-content: space-between;
                margin: 0;
                li {
                    display: inline-block;
                    a {
                        padding: 0;
                        position: relative;
                    }
                    &:hover,
                    &.active {
                        a {
                            color: $highlight;
                            text-decoration: none;
                            &:after {
                                background-color: $highlight;
                                content: '';
                                height: 2px;
                                left: 0;
                                position: absolute;
                                top: -30px;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
        .wrapper {
            max-width: 1170px;
            padding: 0 .5em;
        }
    }

    // Fix Wordpress
    .category .menu-home a,
    .single .menu-home a,
    .search .menu-home a,
    .archive .menu-home a {
        color: #3e3e3e !important;
    }
    .category .menu-home a:after,
    .single .menu-home a:after,
    .search .menu-home a:after,
    .archive .menu-home a:after {
        display: none;
    }
    .category .menu-home:hover a,
    .single .menu-home:hover a,
    .search .menu-home:hover a,
    .archive .menu-home:hover a {
        color: $highlight !important;
    }
    .category .menu-home:hover a:after,
    .single .menu-home:hover a:after,
    .search .menu-home:hover a:after,
    .archive .menu-home:hover a:after {
        display: inline-block;
    }
}

// >= 640 (Foundation == Medium)
@media (min-width: 40em) {
    .industry {
        .industry-list {
            ul {
                column-count: 2;
            }
        }
    }
}

// >= 768
@media (min-width: 48em) {
    main {
        padding-top: 191px;
    }
    .search-bar {
        padding: 50px 10px 30px 10px;
        .wrapper {
            max-width: 1170px;
        }
        &__form {
            display: flex;
            justify-content: space-between;
            div {
                display: inline-block;
            }
            &--input {
                margin: 0;
            }
            &--select {
                margin: 0;
            }
        }
    }
    .content {
        &:after {
            clear: both;
            content: '';
            display: table;
        }
        &__main {
            border-right: 2px $light solid;
            float: left;
            padding-right: 1rem;
            width: 65%;
        }
        &__sidebar {
            float: left;
            padding-left: 1rem;
            width: 35%;
        }
    }
}

// >= 960
@media (min-width: 60em) {
    footer {
        padding: 45px .5rem;
        ul {
            display: flex;
            justify-content: space-between;
            li {
                margin-bottom: 0;
            }
        }
        &:after {
            clear: both;
            content: '';
            display: table;
        }
        .footer-left {
            border-right: 2px $light solid;
            float: left;
            padding-right: 1rem;
            width: 65%;
            .logos {
                height: 58px;
                justify-content: start;
                margin-bottom: 2rem;
                li {
                    padding-right: 74px;
                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
        }
        .footer-right {
            float: left;
            padding-left: 1rem;
            width: 35%;
            .social {
                height: 58px;
                margin-bottom: 2rem;
            }
        }
    }
    .search-bar {
        &__form {
            &--input {
                margin: 0;
                width: 400px;
            }
        }
    }
}

// >= 1024 (Foundation == Large)
@media (min-width: 64em) {
    footer {
        .footer-left {
            padding-right: 1.5rem;
            width: 62.5%;
        }
        .footer-right {
            padding-left: 1.5rem;
            width: 37.5%;
        }
    }
    .search-bar {
        &__form {
            &--input {
                margin: 0;
                width: 500px;
            }
        }
    }
    .content {
        &__main {
            padding-right: 1.5rem;
            width: 62.5%;
        }
        &__sidebar {
            padding-left: 1.5rem;
            width: 37.5%;
        }
    }

    .search-filter {
        .apply {
            width: 60%;
        }
        .reset {
            margin-left: 10%;
            width: 30%;
        }
    }
}

// >= 1104
@media (min-width: 69em) {
    footer {
        .footer-left {
            padding-right: 2rem;
        }
        .footer-right {
            padding-left: 2rem;
        }
    }
    .search-bar {
        &__form {
            &--input {
                margin: 0;
                width: 600px;
            }
        }
    }
    .content {
        &__main {
            padding-right: 2rem;
        }
        &__sidebar {
            padding-left: 2rem;
        }
    }
}

// >= 1170
@media (min-width: 73.125em) {
    footer {
        .footer-left {
            padding-right: 3rem;
        }
        .footer-right {
            padding-left: 3rem;
        }
    }
    .search-bar {
        &__form {
            &--input {
                margin: 0;
                width: 670px;
            }
        }
    }
    .content {
        &__main {
            padding-right: 3rem;
        }
        &__sidebar {
            padding-left: 3rem;
        }
    }
}