.breadcrumbs {
    margin: 0 auto;
    max-width: 1170px;
    padding: 32px 10px 48px 10px;
    nav {
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            li {
                color: #575757;
                display: inline-block;
                font-size: 16px;
                padding-right: 30px;
                position: relative;
                text-transform: capitalize;
                &:after {
                    bottom: 3px;
                    content: url('../img/dot.svg');
                    position: absolute;
                    right: 10px;
                }
                &:last-child:after {
                    content: '';
                }
                a {
                    color: #9b9b9b;
                    text-decoration: none;
                }
            }
        }
    }
}