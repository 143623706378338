* {
    box-sizing: border-box;
}

html {
    font-size: $rem-base;
}

body {
    color: #515151;
    font-family: 'Roboto', sans-serif;
    font-size: $rem-base;
    margin: 0;
}

a {
    outline: none;
}

.wrapper {
    margin: 0 auto;
}

.responsive {
    height: auto;
    max-width: 100%;
}

main {
    padding-top: 102px;
}

.divider {
    height: 95px;
    position: relative;
    &:after {
        background-color: $light;
        content: '';
        height: 2px;
        position: absolute;
        top: 47.5px;
        width: 100%;
    }
}
.double-divider {
    height: 95px;
    position: relative;
    &:before {
        background-color: $light;
        content: '';
        height: 2px;
        position: absolute;
        top: 41.5px;
        width: 100%;
    }
    &:after {
        background-color: $light;
        content: '';
        height: 2px;
        position: absolute;
        top: 47.5px;
        width: 100%;
    }
}