.subscribe {
    h1,
    &__title {
        @include heading1(44px);
        margin: 0 0 24px 0;
    }
    &__content {
        h2 {
            @include frankBkCom(44px);
            margin: 0;
        }
        h2 {
            @include frankBkCom(36px);
            margin: 0;
        }
    }
}

.contact {
    h2 {
        @include frankBkCom(36px);
        margin: 0;
    }
    &__title {
        font-size: 16px;
        margin: 0;
    }
    &__value {
        color: $highlight;
        font-size: 16px;
        margin: 0 0 32px 0;
        & a{
            color: $highlight;
            text-decoration: none;
        }
    }
}