.search-bar {
    background: $dark-gradient;
    padding: 10px;
    text-align: center;
    &__form {
        &--input {
            border-bottom: 2px #fff solid;
            margin: 0 auto 1rem auto;
            position: relative;
            width: 270px;
            &:after {
                background-color: $highlight;
                bottom: -2px;
                content: '';
                height: 2px;
                left: 0;
                position: absolute;
                width: 45px;
            }
            input {
                background-color: rgba(0,0,0,0);
                background-image: url(../img/search_icon.svg);
                background-position: left 0 top 0;
                background-repeat: no-repeat;
                border: none;
                color: #fff;
                height: 50px;
                line-height: 50px;
                outline: none;
                padding: 0 35px 0 55px;
                transition: all .5s;
                width: 100%;
                &::placeholder {
                    color: #fff;
                    font-size: 32px;
                }
            }
        }
        &--select {
            border-bottom: 2px #fff solid;
            margin: 0 auto;
            position: relative;
            width: 270px;
            &:after {
                background-color: $highlight;
                bottom: -2px;
                content: '';
                height: 2px;
                position: absolute;
                right: 0;
                width: 50px;
            }
            select {
                appearance: none;
                background-color: rgba(0,0,0,0);
                background-image: url(../img/Arrow.svg);
                background-position: right 15px top 25px;
                background-repeat: no-repeat;
                border: none;
                border-radius: 0;
                color: #fff;
                height: 50px;
                outline: none;
                padding: 10px 35px 0 5px;
                position: relative;
                width: 100%;
                transition: background-position .2s ease-out;
                &:hover {
                    background-position: right 15px top 30px;
                }
                option {
                    color: $dark;
                }
            }
        }
        &--submit {
            margin-top: 1rem;
        }
    }
}


.search-header {
    margin-bottom: 60px;
    &__title {
        @include heading1(56px);
        margin: 0 0 27px 0;
    }
    &__sort {
        &--left {
            h3 {
                @include frankBkCom(32px);
                color: #3e3e3e;
                margin: 0;
            }
        }
        &--right {
            &:after {
                clear: both;
                content: '';
                display: table;
            }
            h3 {
                @include frankBkCom(32px);
                color: #3e3e3e;
                display: inline-block;
                float: left;
                margin: 0;
            }
            form {
                display: inline-block;
                float: right;
            }
            select {
                appearance: none;
                background-color: rgba(0,0,0,0);
                background-image: url(../img/Arrow-gray.svg);
                background-position: right 15px top 20px;
                background-repeat: no-repeat;
                border: 1px $medium solid;
                border-radius: 0;
                color: $medium;
                display: inline-block;
                height: 50px;
                outline: none;
                padding: 10px 45px 10px 5px;
                position: relative;
                width: 170px;
                option {
                    color: $dark;
                }
            }
        }
    }
}

.search-filter {
    &__title {
        @include frankBkCom(32px);
        margin: 0 0 32px 0;
    }
    &__sub-title {
        @include frankHv(20px);
        margin: 0 0 24px 0;
        text-transform: uppercase;
    }
    input[type="radio"] {
        appearance: none;
        background-color: #fff;
        background-image: none;
        border: 2px solid #3E3E3E;
        border-radius: 16px;
        height: 32px;
        margin-bottom: 12px;
        position: relative;
        width: 32px;
        vertical-align: middle;
        &:focus {
            outline: none;
        }
        &:checked:after {
            @include frankHv(39px);
            color: $highlight;
            content: '×';
            position: absolute;
            top: -4.5px;
            left: 2px;
        }
    }
    label {
        color: #9b9b9b;
        margin-bottom: 12px;
        margin-left: 10px;
        text-transform: capitalize;
        vertical-align: top;
    }
    .apply {
        display: inline-block;
        margin-bottom: 32px;
        margin-right: -4px;
        width: 100%;
    }
    .reset {
        display: inline-block;
        margin-bottom: 32px;
        margin-right: -4px;
        text-decoration: none;
        width: 100%;
    }
}




.search-filters{
  li a{
    
    padding-left: 35px;
    background: url("../img/unchecked.jpg") no-repeat;
    
    &.active{
      background: url("../img/checked.jpg") no-repeat;
    }
  }
}