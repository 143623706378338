.content {
    margin: 0 auto;
    max-width: 1170px;
    padding: 0 .5rem;
}

.info-box {
    background-color: $light;
    border-left: 2px $highlight solid;
    padding: 24px;
    a.button {
        text-decoration: none;
        width: 100%;
    }
}

.ad-box {
    text-align: center;
}

.sponsor-box {
    text-align: center;
    span {
        @include frankHv(14px);
        color: #9b9b9b;
        display: block;
        text-align: left;
    }
}