.upcoming-event {
    border-bottom: 2px $light solid;
    margin: 0 auto 52.5px auto;
    max-width: 1170px;
    padding: 0 .5rem .5rem .5rem;
    h1 {
        @include heading1(56px);
        margin: 0 0 24px 0;
    }
    h2 {
        @include frankBkCom(44px);
    }
    &__image {
        img {
            height: 370px;
            object-fit: cover;
            width: 1170px;
        }
    }
    &__title {
        @include frankBkCom(36px);
        margin: 27px 0 0 0;
    }
    &__stats {
        margin: 0 0 1rem 0;
        &--date {
            @include frankHv(16px);
            margin: 0 5px 0 0;
            padding-right: 15px;
            position: relative;
            &:after {
                bottom: 0;
                content: url('../img/dot.svg');
                position: absolute;
                right: 0;
            }
        }
        &--location {
            color: #9b9b9b;
            font-size: 16px;
            text-decoration: none;
            text-transform: uppercase;
        }
    }
    &__copy {
        p {
            margin: 0 0 1rem 0;
        }
    }
    a.button {
        text-decoration: none;
        width: 100%;
    }
}
.dc-event-heading {
    @include frankBkCom(44px);
    margin: 0;
}
.dc-event {
    border-bottom: 2px $light solid;
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    &:last-child {
        border-bottom: none;
    }
    &__image {
        img {
            height: 400px;
            object-fit: cover;
            width: 767px;
        }
    }
    &__title {
        @include frankBkCom(36px);
        line-height: 1.0;
        margin: 27px 0 0 0;
    }
    &__stats {
        margin: 0;
        &--date {
            @include frankHv(16px);
            margin: 0 5px 0 0;
            padding-right: 15px;
            position: relative;
            &:after {
                bottom: 0;
                content: url('../img/dot.svg');
                position: absolute;
                right: 0;
            }
        }
        &--location {
            color: #9b9b9b;
            font-size: 16px;
            text-decoration: none;
            text-transform: uppercase;
        }
    }
    a.button {
        text-decoration: none;
        width: 100%;
    }
}
.industry-event-heading {
    @include frankBkCom(36px);
    margin: 0 0 10px 0;
}
.industry-event {
    border-bottom: 2px $light solid;
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    &:last-child {
        border-bottom: none;
    }
    &__image {
        img {
            height: 400px;
            object-fit: cover;
            width: 767px;
        }
    }
    &__title {
        @include frankBkCom(36px);
        line-height: 1.0;
        margin: 27px 0 0 0;
    }
    &__stats {
        margin: 0;
        &--date {
            @include frankHv(16px);
            margin: 0 5px 0 0;
            padding-right: 15px;
            position: relative;
            &:after {
                bottom: 0;
                content: url('../img/dot.svg');
                position: absolute;
                right: 0;
            }
        }
        &--location {
            color: #9b9b9b;
            font-size: 16px;
            text-decoration: none;
            text-transform: uppercase;
        }
    }
    a.button {
        text-decoration: none;
        width: 100%;
    }
}