.share-this {
    padding: 45px 0 0 0;
    .st-custom-button {
        background-color: #fff;
        border: 2px $highlight solid;
        border-radius: 17.5px;
        cursor: pointer;
        display: inline-block;
        height: 35px;
        margin-right: 45px;
        transition: all .3s linear;
        width: 35px;
        &:last-child {
            margin-right: 0;
        }
        svg {
            max-height: 15px;
            width: auto;
        }
        #twitter,
        #linkedin,
        #facebook,
        #envelope {
             fill: $dark;
             transition: all .3s linear;
        }
        &:hover {
            background-color: $highlight;
            #twitter,
            #linkedin,
            #facebook,
            #envelope {
                 fill: #fff;
            }
        }
    }
}