.post-list {
    position: relative;
    a {
        text-decoration: none;
        &:hover {
            color: $highlight;
        }
    }
    .stats {
        margin: 0;
    }
    &__post {
        border-bottom: 2px $light solid;
        margin: 0 0 60px 0;
        padding: 0 0 40px 0;
        &:last-child {
            border: 0;
        }
        &--title {
            @include frankBkCom(44px);
            color: #7e7e7e;
            line-height: 50px;
            margin: 0;
            &:hover {
                color: $highlight;
            }

        }
        &--date {
            @include frankHv(16px);
            color: $dark;
            margin-right: 5px;
            padding-right: 15px;
            position: relative;
            &:after {
                bottom: 0;
                content: url('../img/dot.svg');
                position: absolute;
                right: 0;
            }
        }
        &--category {
            color: #9b9b9b;
            font-size: 16px;
            margin-right: 5px;
            padding-right: 15px;
            position: relative;
            text-decoration: none;
            text-transform: uppercase;
            &:after {
                bottom: 0;
                content: url('../img/dot.svg');
                position: absolute;
                right: 0;
            }
        }
        &--keyword {
            color: #9b9b9b;
            font-size: 16px;
            text-decoration: none;
            text-transform: uppercase;
        }
        &--teaser-text {

        }
    }
}