.info-box {
    form {
        .form-intro {
            padding: 12px 0;
        }
        h3, p {
            margin: 0;
            padding: 0;
        }
        h3 {
            @include frankBkCom(36px);
            line-height: 1;
            text-transform: uppercase;
        }
        p {
            @include frankBkCom(22px);
            line-height: 1;
        }
        input[type="text"],
        input[type="email"] {
            border: none;
            height: 52px;
            margin-bottom: 24px;
            padding: 10px;
            width: 100%;
        }
        input[type="submit"] { 
            width: 100%;
        }
        textarea {
            border: none;
            margin-bottom: 24px;
            min-height: 208px;
            padding: 10px;
            width: 100%;
        }
    }
}