.industry {
    .industry-list {
        margin-bottom: 75px;
        h1,
        h2 {
            @include heading1(56px);
            display: inline-block;
            &:before {
                background-color: $light;
                bottom: -10px;
                content: '';
                height: 2px;
                left: 0;
                position: absolute;
                width: 80%;
            }
        }
        ul {
            column-count: 1;
            list-style-type: none;
            margin: 30px 0;
            padding: 0;
            li {
                margin-bottom: 15px;
                a {
                    @include frankHv(20px);
                    color: $dark;
                    text-decoration: none;
                    text-transform: uppercase;
                    &:hover {
                        color: $highlight;
                    }
                }
            }
        }
    }
}
.industry-article {
    margin-bottom: 75px;
    h2 {
        @include frankBkCom(44px);
    }
    h3 {
        @include frankBkCom(36px);
    }
    &__title {
        @include heading1(56px);
        display: inline-block;
    }
    &__name {
        @include frankMd(18px);
        color: #9b9b9b;
    }
    &__stats {
        &--date {
            @include frankHv(16px);
            margin-right: 5px;
            padding-right: 15px;
            position: relative;
            &:after {
                bottom: 0;
                content: url('../img/dot.svg');
                position: absolute;
                right: 0;
            }
        }
        &--category {
            color: #9b9b9b;
            font-size: 16px;
            margin-right: 5px;
            padding-right: 15px;
            position: relative;
            text-decoration: none;
            text-transform: uppercase;
            &:after {
                bottom: 0;
                content: url('../img/dot.svg');
                position: absolute;
                right: 0;
            }
        }
        &--keyword {
            color: #9b9b9b;
            font-size: 16px;
            text-decoration: none;
            text-transform: uppercase;
        }
    }
}