footer {
    border-top: 2px $light solid;
    margin: 90px auto 0 auto;
    max-width: 1170px;
    padding: 45px 0;
    text-align: center;
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
            margin-bottom: 1rem;
            text-transform: capitalize;
        }
    }
    .footer-left {
        .links {
            li {
                color: #7e7e7e;
                font-size: 16px;
                a {
                    color: #7e7e7e;
                    text-decoration: none;
                    &:hover {
                        color: $highlight;
                    }
                }
            }
        }
    }
    .footer-right {
        .social {
            li {
                background-color: #fff;
                border: 2px $highlight solid;
                border-radius: 29px;
                display: inline-block;
                height: 58px;
                padding-top: 14px;
                transition: all .3s linear;
                width: 58px;
                svg {
                    max-height: 20px;
                    width: auto;
                }
                #linkedin,
                #twitter,
                #envelope {
                     fill: $dark;
                     transition: all .3s linear;
                }
                &:hover {
                    background-color: $highlight;
                    #linkedin,
                    #twitter,
                    #envelope {
                         fill: #fff;
                    }
                }
            }
        }
        .contact {
            li {
                color: #7e7e7e;
                font-size: 16px;
                text-transform: lowercase;
                a {
                    color: #7e7e7e;
                    text-decoration: none;
                    &:hover {
                        color: $highlight;
                    }
                }
            }
        }
    }
}
