.user-content {
    h1,
    &__title {
        @include heading1(56px);
        margin: 0 0 24px 0;
    }
    h2 {
        @include frankBkCom(44px);
        margin: 0;
    }
    h2 {
        @include frankBkCom(36px);
        margin: 0;
    }
    a {
        color: $highlight;
    }
}
