#nav-toggle {
    background-color: transparent;
    border: none;
    color: $dark;
    cursor: pointer;
    font-size: 1.5em;
    outline: none;
    position: absolute;
    right: .5em;
    top: .75rem;
    z-index: 1001;
}
.header {
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 100;
    &__logo {
        display: inline-block;
        height: auto;
        padding: 32px 0 15px 0;
        transition: padding .3s ease-out;
        width: 100%;
        a {
            display: inline-block;
            height: 55px;
            width: 273px;
        }
    }
    &__nav {
        background: rgba(255,255,255, 0.9);
        left: 0;
        min-height: 100vh;
        overflow: hidden;
        padding: .5em 0;
        position: absolute;
        transition: width .3s ease-out;
        top: 0;
        width: 0;
        z-index: 1;
        &--primary {
            list-style-type: none;
            margin: 1em;
            padding: 0;
            li {
                a {
                    @include roboBd(18px);
                    color: $dark;
                    display: block;
                    letter-spacing: .05rem;
                    padding: 1em;
                    text-decoration: none;
                    text-transform: uppercase;
                    &:hover,
                    &.active {
                        text-decoration: underline;
                    }
                }
            }
        }
        &.show-nav {
            width: 100vw;
        }
    }
}