.channel-article {
    &__title {
        @include frankBkCom(44px);
        line-height: 44px;
        margin: 0;
        
        a {
            color: $dark;
            text-decoration: none;
            &:hover {
                color: $highlight;
            }
        }
    }
    &__poster {
        overflow: hidden;
        padding-top: 56.25%;
        position: relative;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
        }
    }

    &__name {
        @include frankMd(18px);
        margin-top: 0;
        color: #9b9b9b;
    }
    &__stats {
        &--date {
            @include frankHv(16px);
            margin-right: 5px;
            padding-right: 15px;
            position: relative;
            &:after {
                bottom: 0;
                content: url('../img/dot.svg');
                position: absolute;
                right: 0;
            }
        }
        &--category {
            color: #9b9b9b;
            font-size: 16px;
            margin-right: 10px;
            padding-right: 15px;
            position: relative;
            text-decoration: none;
            text-transform: uppercase;
            &:after {
                bottom: 0;
                content: url('../img/dot.svg');
                position: absolute;
                right: 0;
            }
            &:hover {
                color: $highlight;
            }
        }
        &--keyword {
            color: #9b9b9b;
            font-size: 16px;
            margin-right: 10px;
            padding-right: 15px;
            position: relative;
            text-decoration: none;
            text-transform: uppercase;
            &:after {
                bottom: 0;
                content: url('../img/dot.svg');
                position: absolute;
                right: 0;
            }
            &:last-child {
                margin-right: 0;
                padding-right: 0;
                &:after {
                    content: '';
                }
            }
            &:hover {
                color: $highlight;
            }
        }
    }
    .sponsor-box {
        text-align: left;
    }
    .readmore {
        color: $dark;
        text-decoration: none;
    }
}

.single .channel-article .channel-article__title {
    @include heading1(56px);
    display: inline-block;
}

nav.pagination {
    margin: 3em 0 0 0;
    text-align: center;
    width: 100%;
    h2 {
        display: none;
    }
    .nav-links {
        .page-numbers {
            background-color: #fff;
            border-radius: 2px;
            color: $dark;
            cursor: pointer;
            display: inline-block;
            font-size: 16px;
            height: 23px;
            margin: 0 .5em;
            text-align: center;
            text-decoration: none;
            width: 23px;
            &.current {
                background-color: $highlight;
                border-radius: 2px;
                color: #fff;
                display: inline-block;
                font-size: 16px;
                height: 23px;
                margin: 0 .5em;
                text-align: center;
                width: 23px;
            }
            &.prev,
            &.next {
                display: none;
            }
        }
    }
}